.wrapper {
    display: grid;
    grid-template-columns:
      1fr
      min(65ch, 100%)
      1fr;
  }

.wrapper > * {
grid-column: 2;
}

.full-bleed {
    width: 100%;
    grid-column: 1 / 4;
}